import { Injectable, inject } from '@angular/core';
import { GraphDotnetService, graphql, MediaclipMutationUpdateInvoiceArgs, UpdateInvoiceInput } from 'app/shared/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoiceService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    adjustInvoiced(input: UpdateInvoiceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateInvoiceArgs>(graphql`
                mutation updateInvoice($input: UpdateInvoiceInput!) {
                    updateInvoice(input: $input)
                }
            `,
            { input }
        ).pipe(map(result => result.updateInvoice));
    }
}
