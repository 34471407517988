import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { UpdateSubscriptionInput } from 'app/shared/graph';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatSuffix, MatHint } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'admin-update-subscription-billing-dialog',
    templateUrl: 'update-subscription-billing-dialog.component.html',
    styleUrls: ['update-subscription-billing-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatDatepickerInput, MatDatepickerToggle, MatSuffix, MatDatepicker, MatSelect, MatOption, MatCheckbox, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class UpdateSubscriptionBillingDialogComponent {
    dialogRef = inject<MatDialogRef<UpdateSubscriptionBillingDialogComponent>>(MatDialogRef);
    data = inject<UpdateSubscriptionInput>(MAT_DIALOG_DATA);

    currencyPattern = /^[A-Z]{3}$/;
}
