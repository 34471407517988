import {
    CreateFulfillerAppInput,
    CreateFulfillerInput,
    Fulfiller,
    FulfillerApp,
    GraphDotnetService, graphql,
    Maybe,
    MediaclipMutationCreateFulfillerAppArgs,
    MediaclipMutationCreateFulfillerArgs,
    MediaclipMutationUpdateFulfillerArgs,
    UpdateFulfillerInput
} from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FulfillerService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    list(): Observable<Fulfiller[]> {
        return this.graphDotnetService.query(graphql`
                query getFulfillers {
                    fulfillers {
                        id
                        libraryPackage
                        url
                        client
                    }
                }
            `
        ).pipe(map(e => e.fulfillers));
    }

    getAuthorizedUsers(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        authorizedUsers {
                            user {
                                email
                                picture
                            }
                            roles
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }


    getFulfillerSecrets(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        fulfillerAppId
                        secrets {
                            id
                            label
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }

    load(fulfillerId: string): Observable<Maybe<Fulfiller> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        id
                        client
                        fulfillerAppId
                        libraryPackage
                        url
                        mappingScript {
                            script
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => e.fulfiller));
    }

    createFulfiller(input: CreateFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateFulfillerArgs>(graphql`
                mutation createFulfiller($input: CreateFulfillerInput!) {
                    createFulfiller(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.createFulfiller));
    }

    updateFulfiller(input: UpdateFulfillerInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateFulfillerArgs>(graphql`
                mutation updateFulfiller($input: UpdateFulfillerInput!) {
                    updateFulfiller(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.updateFulfiller));
    }

    createFulfillerApp(input: CreateFulfillerAppInput): Observable<FulfillerApp> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateFulfillerAppArgs>(graphql`
                mutation createFulfillerApp($input: CreateFulfillerAppInput!) {
                    createFulfillerApp(input: $input) {
                        id
                        displayName
                    }
                }
            `, { input }
        ).pipe(map(x => x.createFulfillerApp));
    }
}
