import { Anomalies, GraphDotnetService, graphql, RetryAnomalies } from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnomaliesService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    load(): Observable<Anomalies> {
        return this.graphDotnetService.query(
            graphql`
                query getAnomalies {
                    anomalies {
                        lines {
                            __typename
                            id
                            fulfillerHubLineNumber
                            storeOrderLineNumber
                            dateCreatedUtc
                            order {
                                id
                                storeOrderId
                            }
                            status {
                                value
                                flags
                            }
                            store {
                                id
                            }
                        }
                    }
                }
            `)
            .pipe((map(q => q.anomalies)));
    }

    retryAll(): Observable<RetryAnomalies> {
        return this.graphDotnetService.mutate(graphql`
                mutation retryAllAnomalies {
                    retryOrdersAnomalies {
                        totalAnomalies
                        processedAnomalies
                    }
                }
            `)
            .pipe((map(q => q.retryOrdersAnomalies)));
    }
}
