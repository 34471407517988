<h2 mat-dialog-title>Related Stores</h2>
<mat-dialog-content>
    @if (stockPhotoSource) {
        @if (stockPhotoSource.stores.length > 0) {
            <div class="sub-header">
                The following store(s) use the stock photo source <code>{{ stockPhotoSource.id }}</code>
            </div>
            @for (store of stockPhotoSource.stores; track store) {
                <div class="related-store">
                    <button mat-button (click)="openStoreSettings(store.id)">
                        <span
                        >{{ store.label }} <code>{{ store.id }}</code></span
                        >
                    </button>
                </div>
            }
        }
        @if (stockPhotoSource.stores.length === 0) {
            <div class="sub-header">
                No store configured to use the stock photo source <code>{{ stockPhotoSource.id }}</code>
            </div>
        }
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</mat-dialog-actions>
