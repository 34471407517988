@if (data.mode === "create") {
    <h2 mat-dialog-title>Add filter category</h2>
}
@if (data.mode === "edit") {
    <h2 mat-dialog-title>Edit filter category</h2>
}
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Label</mat-label>
            <input
                matInput
                name="label"
                [(ngModel)]="filterCategoryInfo.label"
                (ngModelChange)="verifyUnicityLabel($event)"
                pattern="[a-zA-Z0-9\-]+"
                required
            />
            <mat-error>Only use A-Z and a-z and 0-9 and -</mat-error>
            <mat-hint>Only use A-Z and a-z and 0-9 and -</mat-hint>
        </mat-form-field>
        @if (!this.IsValid) {
            <p class="error">This category already exists.</p>
        }
        <mat-form-field>
            <mat-label>Built-in Filters</mat-label>
            <mat-select multiple [formControl]="builtInFilters">
                @for (filter of this.data.filterBuiltIn; track filter) {
                    <mat-option [value]="filter.id">{{ filter.displayName }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Remote Filters</mat-label>
            <mat-select multiple [formControl]="remoteFilters">
                @for (filter of this.data.remoteFilters; track filter) {
                    <mat-option [value]="filter.id">{{ filter.name }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="form.invalid || !this.IsValid">Ok</button>
</mat-dialog-actions>
