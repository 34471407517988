<h2 mat-dialog-title>Add Stock Photo Source</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Identifier</mat-label>
            <input name="id" matInput pattern="[a-zA-Z0-9\-]+" [(ngModel)]="stockPhotoSourceInfo.id" required />
            <mat-error>Only alphanumeric and - are allowed</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Display name</mat-label>
            <input name="label" matInput [(ngModel)]="stockPhotoSourceInfo.label" />
            <mat-hint>Optional label displayed in the Designer</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="!form.valid">Create</button>
</mat-dialog-actions>
