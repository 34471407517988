<admin-page-simple-content
    id="customCss"
    [pageError]="pageError"
    [pageStatus]="pageStatus"
    [title]="'Designer: Custom CSS'"
    [icon]="'description'"
>
    <div class="content">
        <div class="header">
            <div class="description">
                <p>
                    This page allows you to update a custom css that will be included in the designer context. See
                    <a href="https://doc.mediaclip.ca/hub/features/designer-customization/" target="_blank"
                    >documentation</a
                    >
                    for more information.
                </p>
                <p>
                    Please take note that Mediaclip does not support your custom code. By uploading overrides, we cannot
                    guarantee compatibility of your code for all browsers, and even though we try to minimize changes,
                    future Mediaclip Designers versions may break any non-documented hooks and css changes.
                </p>
            </div>
            <div class="actions">
                <input
                    #file
                    type="file"
                    (change)="onFilesChanged($any($event.target).files)"
                    accept="text/css"
                    style="display: none"
                />
                <button mat-stroked-button (click)="file.click()">
                    <mat-icon>upload</mat-icon>
                    Load from file...
                </button>
                <admin-save-button [pageStatus]="pageStatus" (save)="save()"> Save</admin-save-button>
            </div>
        </div>
        @if (pageStatus === "saveError" && pageError) {
            <admin-error [error]="pageError"></admin-error>
        }
        <div class="editor">
            <ngx-codemirror
                [(ngModel)]="css"
                [options]="{
                    lineNumbers: true,
                    matchBrackets: true,
                    lint: true,
                    theme: 'material',
                    mode: 'text/css',
                    extraKeys: { 'Ctrl-S': save.bind(this) },
                }"
            ></ngx-codemirror>
        </div>
    </div>
</admin-page-simple-content>
