import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService, graphql, StoreFulfiller } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class StoreFulfillersService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getStoreFulfillers(storeId: string): Observable<Array<StoreFulfiller>> {
        return this.graphDotnetService.query(graphql`
                query getFulfillers($storeId: String!) {
                    store(id: $storeId) {
                        fulfillers {
                            fulfillerId
                            isDefault
                        }
                    }
                }
            `,
            { storeId }
        ).pipe(map(x => x.store.fulfillers));
    }
}
