import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    CancelSubscriptionPendingProjectExpirationsInput,
    CreateStoreInput,
    DeactivateSubscriptionInput,
    GraphDotnetService, graphql,
    MediaclipMutationCancelSubscriptionPendingProjectExpirationsArgs,
    MediaclipMutationCreateStoreArgs,
    MediaclipMutationDeactivateSubscriptionAndScheduleDeletionOfUserDataArgs,
    MediaclipMutationUpdateSubscriptionCleanupDelayArgs,
    Subscription,
    UpdateSubscriptionCleanupDelayInput,
    UpdateSubscriptionInput
} from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {
    private readonly graphDotnetService = inject(GraphDotnetService);



    load(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(graphql`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        contact
                        active
                        deactivationDateUtc
                        storesCount
                        notes
                        stores {
                            id
                            label
                            environment
                            status
                            projectsCount(sinceDays: 14)
                            ordersCount(sinceDays: 14)
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    loadWithCleanup(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(graphql`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        cleanup {
                            projectExpirations {
                                giftingDelayInDays
                                printDelayInDays
                                photobookDelayInDays
                            }
                            pendingProjectExpirations {
                                giftingDelayInDays
                                printDelayInDays
                                photobookDelayInDays
                                changedConfigurationDateUtc
                            }
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    edit(input: UpdateSubscriptionInput): Observable<any> {
        return this.graphDotnetService.mutate<any>(graphql`
                mutation updateSubscription($input: UpdateSubscriptionInput!) {
                    updateSubscription(input: $input)
                }
            `, { input }
        );
    }

    updateSubscriptionCleanupDelay(input: UpdateSubscriptionCleanupDelayInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateSubscriptionCleanupDelayArgs>(
            graphql`
                mutation updateSubscriptionCleanupDelay($input: UpdateSubscriptionCleanupDelayInput!) {
                    updateSubscriptionCleanupDelay(input: $input)
                }
            `,
            { input }
        );
    }

    cancelSubscriptionPendingProjectExpirations(input: CancelSubscriptionPendingProjectExpirationsInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationCancelSubscriptionPendingProjectExpirationsArgs>(
            graphql`
                mutation cancelSubscriptionPendingProjectExpirations($input: CancelSubscriptionPendingProjectExpirationsInput!) {
                    cancelSubscriptionPendingProjectExpirations(input: $input)
                }
            `,
            { input }
        );
    }

    deactivateSubscriptionAndScheduleDeletionOfUserData(input: DeactivateSubscriptionInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeactivateSubscriptionAndScheduleDeletionOfUserDataArgs>(
            graphql`
                mutation deactivateSubscriptionAndScheduleDeletionOfUserData($input: DeactivateSubscriptionInput!) {
                    deactivateSubscriptionAndScheduleDeletionOfUserData(input: $input)
                }
            `,
            { input }
        );
    }

    createStore(input: CreateStoreInput) {
        return this.graphDotnetService.mutate<MediaclipMutationCreateStoreArgs>(
            graphql`
                mutation createStore($input: CreateStoreInput!) {
                    createStore(input: $input) {
                        id
                        label
                        environment
                        status
                        projectsCount(sinceDays: 14)
                        ordersCount(sinceDays: 14)
                    }
                }
            `,
            { input: input }
        ).pipe(
            map(m => m.createStore!)
        );
    }
}
