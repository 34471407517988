import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MediaclipMutation, MediaclipQuery } from './schema-dotnet';

export const graphql = (strings: TemplateStringsArray, ...values: any[]) => String.raw({ raw: strings }, ...values);

@Injectable({ providedIn: 'root' })
export class GraphDotnetService {
    private readonly httpClient = inject(HttpClient);

    public accessToken: string = '';
    mutate<TArg>(query: string, variables?: TArg): Observable<MediaclipMutation>;
    mutate(query: string, variables?: any): Observable<MediaclipMutation> {
        const url = environment.endpoints.graphDotnetUrl;

        return this.httpClient.post<{ data: MediaclipMutation, errors: any[] }>(url,
            { query, variables },
            {
                headers: {
                    Authorization: `Auth0Bearer ${this.accessToken}`
                }
            }
        ).pipe(
            catchError(GraphDotnetService.throwErrorWithAllInfo(query)),
            map((response) => {
                if (response.errors?.length > 0) {
                    throw new GraphDotnetError(response.errors[0].message, { query: query, responseBody: JSON.stringify(response, null, '  ') });
                }
                if (!response.data) {
                    throw new Error('No data in response data');
                }
                return response.data;
            })
        );
    }
    query(query: string, variables?: any): Observable<MediaclipQuery> {
        const url = environment.endpoints.graphDotnetUrl;
        return this.httpClient.post<{ data: MediaclipQuery, errors: any[] }>(url,
            { query, variables },
            {
                headers: {
                    Authorization: `Auth0Bearer ${this.accessToken}`
                }
            }
        ).pipe(
            catchError(GraphDotnetService.throwErrorWithAllInfo(query)),
            map((response) => {
                if (response.errors?.length > 0) {
                    throw new GraphDotnetError(response.errors[0].message, { query: query, responseBody: JSON.stringify(response, null, '  ') });
                }
                return response.data;
            })
        );
    }

    static throwErrorWithAllInfo(query: string) {
        return (err: Error) => {
            if (err instanceof HttpErrorResponse) {
                return throwError(() => new GraphDotnetError(`Request to graph failed with status: ${err.status}`, {
                    innerError: err,
                    query: query,
                    responseBody: JSON.stringify(err.error, undefined, '  ')
                }));
            }
            return throwError(() => new GraphDotnetError('Request to graph failed', { innerError: err, query: query }));
        };
    }
}

export class GraphDotnetError extends Error {
    public readonly innerError?: Error;
    public readonly query?: string;
    public readonly responseBody?: string;

    constructor(message: string, options?: { innerError?: Error, responseBody?: string, query?: string }) {
        super(message);
        this.innerError = options?.innerError;
        this.query = options?.query;
        this.responseBody = options?.responseBody;

        // Workaround: https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, GraphDotnetError.prototype);
    }
}
