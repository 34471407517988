import { Injectable, inject } from '@angular/core';
import { GraphDotnetService, graphql } from 'app/shared/graph';
import { Observable } from 'rxjs';
import { CreateSubscriptionInput, MediaclipMutationCreateSubscriptionArgs, Subscription } from 'app/shared/graph';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class SubscriptionAdminService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    list(): Observable<Subscription[]> {
        return this.graphDotnetService.query(graphql`
                query getSubscriptions {
                    subscriptions {
                        id
                        label
                        contact
                        active
                        deactivationDateUtc
                        storesCount
                        billing {
                            billingStartDateUtc
                            monthlyFee
                            currency
                            revShare {
                                percentage
                            }
                        }
                    }
                }
            `
        ).pipe(map(r => r.subscriptions));
    }

    createSubscription(input: CreateSubscriptionInput): Observable<Subscription> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateSubscriptionArgs>(
            graphql`mutation createSubscription($input:CreateSubscriptionInput!) {
                createSubscription(input: $input) {
                    id
                    label
                    contact
                    active
                    billing {
                        currency
                    }
                    deactivationDateUtc
                    storesCount
                }
            }`,
            { input: input }
        ).pipe(
            map(m => m.createSubscription!)
        );
    }

}
