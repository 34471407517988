import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphDotnetService, graphql, Maybe, MediaclipMutationRevokeSharedProjectArgs, RevokeSharedProjectInput, SharedProject } from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SharedProjectsService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getSharedProject(sharedProjectId: string): Observable<Maybe<SharedProject> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getSharedProject($sharedProjectId: UUID!) {
                    sharedProject(id: $sharedProjectId) {
                        id
                        storeId
                        sharingMode
                        shareDate
                        expiration
                        projectId
                        projectStorageId
                        project {
                          id
                          storeId
                          store {
                            id
                            subscriptionId
                          }
                        }
                    }
                }
            `,
            { sharedProjectId }
        ).pipe(map((result) => result.sharedProject));
    }

    revokeSharedProject(input: RevokeSharedProjectInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationRevokeSharedProjectArgs>(graphql`
                mutation revokeSharedProject($input: RevokeSharedProjectInput!) {
                    revokeSharedProject(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.revokeSharedProject));
    }
}
