import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { OrderLineStatusValues } from 'app/shared/graph';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface ChangeStatusDialogData {
    lineId: string;
    status: OrderLineStatusValues;
    details: string;
    actionRequired: boolean;
    isSuperAdmin: boolean;
}

@Component({
    selector: 'admin-change-status-dialog',
    templateUrl: 'change-status-dialog.component.html',
    styleUrls: ['change-status-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatSelect, MatOption, MatCheckbox, MatInput, MatDialogActions, MatButton, MatDialogClose]
})
export class ChangeStatusDialogComponent {
    dialogRef = inject<MatDialogRef<ChangeStatusDialogComponent>>(MatDialogRef);
    data = inject<ChangeStatusDialogData>(MAT_DIALOG_DATA);

    public previousStatus: string;
    public statuses: { value: OrderLineStatusValues; viewValue: string }[];

    constructor() {
        const data = this.data;

        this.previousStatus = data.status;

        if (data.isSuperAdmin) {
            this.statuses = [
                { value: OrderLineStatusValues.AvailableForDownload, viewValue: 'Available for download' },
                { value: OrderLineStatusValues.SentToFulfillment, viewValue: 'Sent to fulfillment' },
                { value: OrderLineStatusValues.Shipped, viewValue: 'Shipped' },
                { value: OrderLineStatusValues.Cancelled, viewValue: 'Cancelled' },
                { value: OrderLineStatusValues.Error, viewValue: 'Error' },
                { value: OrderLineStatusValues.OnHold, viewValue: 'On hold' },
                { value: OrderLineStatusValues.WaitingForCorrections, viewValue: 'Waiting for corrections' },
                { value: OrderLineStatusValues.ReviewCompleted, viewValue: 'Review Completed' },
                { value: OrderLineStatusValues.TransferredToCustomerSupport, viewValue: 'Transferred to customer support' },
            ];
        } else {
            this.statuses = [
                { value: OrderLineStatusValues.AvailableForDownload, viewValue: 'Available for download' },
                { value: OrderLineStatusValues.SentToFulfillment, viewValue: 'Sent to fulfillment' },
                { value: OrderLineStatusValues.Shipped, viewValue: 'Shipped' },
                { value: OrderLineStatusValues.Cancelled, viewValue: 'Cancelled' },
                { value: OrderLineStatusValues.WaitingForCorrections, viewValue: 'Waiting for corrections' },
                { value: OrderLineStatusValues.ReviewCompleted, viewValue: 'Review Completed' },
                { value: OrderLineStatusValues.TransferredToCustomerSupport, viewValue: 'Transferred to customer support' },
            ];
        }

        if (!this.statuses.some((s) => s.value === data.status)) {
            this.statuses.unshift({ value: data.status, viewValue: data.status });
        }
    }
}
