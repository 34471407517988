import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphDotnetService, graphql } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class DesignerBranchService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    public switchNonAutomaticUpgradeStoresToDeprecatedStableChannel(): Observable<void> {
        return this.graphDotnetService.mutate(graphql`
        mutation switchNonAutomaticUpgradeStoresToDeprecatedStableChannel() {
            switchNonAutomaticUpgradeStoresToDeprecatedStableChannel()
        }`)
            .pipe(map(x => x.switchNonAutomaticUpgradeStoresToDeprecatedStableChannel));
    }


    public switchStoresOnDeprecatedStableChannelToStableChannel(): Observable<void> {
        return this.graphDotnetService.mutate(graphql`
        mutation switchStoresOnDeprecatedStableChannelToStableChannel() {
            switchStoresOnDeprecatedStableChannelToStableChannel()
        }`)
            .pipe(map(x => x.switchStoresOnDeprecatedStableChannelToStableChannel));
    }
}
