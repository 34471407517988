import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AccessManagementInput,
    Auth0User,
    AvailableRoles, DownloadPath,
    MediaclipMutationDenyAuthenticatedAccessRolesArgs,
    MediaclipMutationGrantAuthenticatedAccessRolesArgs, PagingResultOfUserRoles,
    Store, UserRoles, StoreUserAccessInput, MediaclipMutationGrantAuthenticatedAccessRolesForStoreArgs, FulfillerUserAccessInput, MediaclipMutationGrantAuthenticatedAccessRolesForFulfillerArgs, SubscriptionUserAccessInput, MediaclipMutationGrantAuthenticatedAccessRolesForSubscriptionArgs, LibraryUserAccessInput, MediaclipMutationGrantAuthenticatedAccessRolesForLibraryArgs
} from '../graph/schema-dotnet';
import { map } from 'rxjs/operators';
import { GraphDotnetService, graphql } from '../graph/graph-dotnet-service';

@Injectable({ providedIn: 'root' })
export class AuthorizedUsersService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getAuthorizedUsers(storeId: string): Observable<Store> {
        return this.graphDotnetService.query(graphql`
                query getAuthorizedUsers($storeId: String!) {
                  store(id: $storeId) {
                    id
                    label
                    authorizedUsers {
                      user {
                        email
                        picture
                      }
                      roles
                    }
                  }
                }
            `,
            { storeId }
        ).pipe(map(r => r.store));
    }

    getAvailableRoles(): Observable<AvailableRoles> {
        return this.graphDotnetService.query(graphql`
                query {
                    availableRoles {
                        subscriptionRoles
                        storeRoles
                        globalRoles
                        fulfillerRoles
                        libraryRoles
                    }
                }
            `
        ).pipe(map(r => r.availableRoles));
    }

    getAvailableDownloadPaths(): Observable<DownloadPath[]> {
        return this.graphDotnetService.query(graphql`
                query {
                    availableDownloadPaths {
                        path
                    }
                }
            `
        ).pipe(map(r => r.availableDownloadPaths));
    }

    listUsers(page: number, filter?: string): Observable<PagingResultOfUserRoles> {
        return this.graphDotnetService.query(graphql`
          query loadAdminUsers($page: Int!, $filter: String) {
            adminUsers(page: $page, filter: $filter) {
              items {
                user {
                  id
                  email
                  picture
                  lastLoginDate
                }
                globalRoles
                downloads
              }
              pageInfo {
                hasNextPage
                page
                pageSize
              }
            }
          }
          `, { page, filter }
        ).pipe(map(r => r.adminUsers));
    }

    getUser(email: string): Observable<UserRoles> {
        return this.graphDotnetService.query(graphql`
          query loadAdminUsers($email: String) {
            adminUser(email: $email) {
                user {
                  id
                  email
                  picture
                  lastLoginDate
                }
                globalRoles
                downloads
            }
          }
          `, { email }
        ).pipe(map(r => r.adminUser));
    }

    revokeUserRole(input: AccessManagementInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDenyAuthenticatedAccessRolesArgs>(graphql`
            mutation denyAuthenticatedAccessRoles($input: AccessManagementInput!) {
                denyAuthenticatedAccessRoles (
                    input: $input
                )
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRoles!)
        );
    }

    grantUserRole(input: AccessManagementInput): Observable<Auth0User> {
        return this.graphDotnetService.mutate<MediaclipMutationGrantAuthenticatedAccessRolesArgs>(graphql`
            mutation grantAuthenticatedAccessRoles($input: AccessManagementInput!) {
                grantAuthenticatedAccessRoles (
                    input: $input
                ) {
                    email
                    picture
                }
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRoles!)
        );
    }

    grantUserRoleOnStore(input: StoreUserAccessInput): Observable<Auth0User> {
        return this.graphDotnetService.mutate<MediaclipMutationGrantAuthenticatedAccessRolesForStoreArgs>(graphql`
            mutation grantAuthenticatedAccessRolesForStore($input: StoreUserAccessInput!) {
                grantAuthenticatedAccessRolesForStore(
                    input: $input
                ) {
                    email
                    picture
                }
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRolesForStore!)
        )
    }

    grantUserRoleOnFulfiller(input: FulfillerUserAccessInput): Observable<Auth0User> {
        return this.graphDotnetService.mutate<MediaclipMutationGrantAuthenticatedAccessRolesForFulfillerArgs>(graphql`
            mutation grantAuthenticatedAccessRolesForFulfiller($input: FulfillerUserAccessInput!) {
                grantAuthenticatedAccessRolesForFulfiller(
                    input: $input
                ) {
                    email
                    picture
                }
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRolesForFulfiller!)
        )
    }

    grantUserRoleOnSubscription(input: SubscriptionUserAccessInput): Observable<Auth0User> {
        return this.graphDotnetService.mutate<MediaclipMutationGrantAuthenticatedAccessRolesForSubscriptionArgs>(graphql`
            mutation grantAuthenticatedAccessRolesForSubscription($input: SubscriptionUserAccessInput!) {
                grantAuthenticatedAccessRolesForSubscription(
                    input: $input
                ) {
                    email
                    picture
                }
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRolesForSubscription!)
        )
    }

    grantUserRoleOnLibrary(input: LibraryUserAccessInput): Observable<Auth0User> {
        return this.graphDotnetService.mutate<MediaclipMutationGrantAuthenticatedAccessRolesForLibraryArgs>(graphql`
            mutation grantAuthenticatedAccessRolesForLibrary($input: LibraryUserAccessInput!) {
                grantAuthenticatedAccessRolesForLibrary(
                    input: $input
                ) {
                    email
                    picture
                }
            }
        `, { input }).pipe(
            map(m => m.grantAuthenticatedAccessRolesForLibrary!)
        )
    }
}
