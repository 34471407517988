import { Injectable, inject } from '@angular/core';
import { GraphDotnetService, graphql } from 'app/shared/graph';
import { Observable } from 'rxjs';
import { Subscription, UpdateSubscriptionInput } from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionBillingService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    loadForBilling(subscriptionId: string): Observable<Subscription> {
        return this.graphDotnetService.query(graphql`
                query getSubscription($subscriptionId: UUID!) {
                    subscription(id: $subscriptionId) {
                        id
                        label
                        contact
                        active
                        storesCount
                        notes
                        billing {
                            billingStartDateUtc
                            multiplyByQuantity
                            revShare {
                                calculatedOn
                                percentage
                                minimum
                                maximum
                            }
                            monthlyFee
                            currency
                        }
                        invoices {
                            year
                            month
                            currency
                            generated
                            monthlyFee
                            revenue
                            invoiced
                            invoicedAdjusted
                            linesCount
                            url
                        }
                    }
                }
            `, { subscriptionId }
        ).pipe(map(e => e.subscription));
    }

    edit(input: UpdateSubscriptionInput): Observable<any> {
        return this.graphDotnetService.mutate<any>(graphql`
                mutation updateSubscription($input: UpdateSubscriptionInput!) {
                    updateSubscription(input: $input)
                }
            `, { input }
        );
    }
}
