import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface UpdateSubscriptionProjectExpirationsDialogData {
    photobook?: number;
    gifting?: number;
    print?: number;
}

export interface UpdateSubscriptionProjectExpirationsDialogResult {
    photobook?: number;
    gifting?: number;
    print?: number;
}

@Component({
    templateUrl: 'update-subscription-project-expirations-dialog.component.html',
    styleUrls: ['update-subscription-project-expirations-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, MatDialogActions, MatButton, MatDialogClose]
})
export class UpdateSubscriptionProjectExpirationsDialogComponent {
    dialogRef = inject<MatDialogRef<UpdateSubscriptionProjectExpirationsDialogComponent, UpdateSubscriptionProjectExpirationsDialogResult>>(MatDialogRef);

    result: UpdateSubscriptionProjectExpirationsDialogResult;

    constructor() {
        this.result = {};
    }

    editSubscription() {
        this.dialogRef.close(this.result);
    }
}
