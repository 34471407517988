<h2 mat-dialog-title>Link fulfiller to an existing App</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>App id</mat-label>
            <input name="fulfillerId" matInput [(ngModel)]="fulfillerInfo.appId" required />
            <mat-hint>The fulfiller app id should be following this format: <code>hakzf29wzt</code></mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="!form.valid">Create</button>
</mat-dialog-actions>
