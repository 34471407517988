<h2 mat-dialog-title>Add a new fulfiller to the store</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Fulfiller</mat-label>
            <input matInput name="fulfillerId" [(ngModel)]="fulfillerId" [matAutocomplete]="auto" required />
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
            @for (id of filteredFulfillerIds | async; track id) {
                <mat-option [value]="id">
                    {{ id }}
                </mat-option>
            }
        </mat-autocomplete>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="form.invalid || !isValueValid()">Create</button>
</mat-dialog-actions>
