<h2 mat-dialog-title>Add Fulfiller</h2>
<mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field>
            <mat-label>Fulfiller id</mat-label>
            <input
                name="fulfillerId"
                matInput
                pattern="[a-zA-Z0-9\-]+"
                [(ngModel)]="fulfillerInfo.fulfillerId"
                required
            />
            <mat-hint>Id used during order checkout</mat-hint>
            <mat-error>Only alphanumeric and - are allowed</mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Client</mat-label>
            <mat-select name="client" [(ngModel)]="fulfillerInfo.client">
                @for (client of clients; track client) {
                    <mat-option [value]="client">{{ client }}</mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="valid()" [disabled]="!form.valid">Create</button>
</mat-dialog-actions>
