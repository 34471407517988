import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { graphql, MediaclipMutationSetRemoteFilterThumbnailArgs, RemoteFilter, SetRemoteFilterThumbnailInput } from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({
    providedIn: 'root'
})
export class RemoteFilterService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    setRemoteFilterThumbnail(input: SetRemoteFilterThumbnailInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationSetRemoteFilterThumbnailArgs>(
            graphql`mutation setRemoteFilterThumbnail($input: SetRemoteFilterThumbnailInput!) {
                setRemoteFilterThumbnail(input: $input)
            }`, {
                input: input
            }).pipe(map(result => result.setRemoteFilterThumbnail));
    }

    loadRemoteFilters(input: string): Observable<RemoteFilter[]> {
        return this.graphDotnetService.query(
            graphql`query LoadStoreRemoteFilters($storeId: String!) {
            store(id: $storeId) {
              id
              remoteFilters {
                id
                name
                alias
                group
                visible
                order
                kind
                urlThumb
                data {
                  ... on GenericRemoteFilterData {
                    url
                    headers {
                      name
                      value
                    }
                  }
                  ... on PerfectlyClearRemoteFilterData {
                    apiKey
                    customPreset
                  }
                  ... on PicsartRemoveBackgroundRemoteFilterData {
                    apiKey
                    outputType
                    backgroundImageUrl
                    backgroundColor
                    backgroundBlur
                    scale
                    format
                  }
                  ... on PicsartEffectsRemoteFilterData {
                    apiKey
                    effect
                  }
                  ... on PicsartStyleTransferRemoteFilterData {
                    apiKey
                    level
                    referenceImageUrl
                  }
                }
              }
            }
          }`, { storeId:input }
        ).pipe(map(result => result.store.remoteFilters))
    }
}
