import { Injectable, inject } from '@angular/core';
import {
    DeleteStorePhotoUpscalingInput, graphql,
    Maybe,
    MediaclipMutationDeleteStorePhotoUpscalingArgs,
    MediaclipMutationUpdateStorePhotoUpscalingArgs,
    PhotoUpscalingConfiguration,
    UpdateStorePhotoUpscalingInput
} from 'app/shared/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class StorePhotosUpscalingService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getConfig(storeId: string): Observable<Maybe<PhotoUpscalingConfiguration> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getStoreUpscaling($storeId: String!) {
                    store(id: $storeId) {
                        photoUpscaling {
                            type
                            enabled
                            minDpiUpscaleConfiguration
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.store.photoUpscaling));
    }

    deleteConfiguration(input: DeleteStorePhotoUpscalingInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteStorePhotoUpscalingArgs>(
            graphql`
                mutation deleteStorePhotoUpscaling($input: DeleteStorePhotoUpscalingInput!) {
                    deleteStorePhotoUpscaling(input: $input)
                }
            `,
            { input }
        );
    }

    updateConfiguration(input: UpdateStorePhotoUpscalingInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStorePhotoUpscalingArgs>(
            graphql`
                mutation UpdateStorePhotoUpscaling($input: UpdateStorePhotoUpscalingInput!) {
                    updateStorePhotoUpscaling(input: $input)
                }
            `,
            { input }
        );
    }
}
