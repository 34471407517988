import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';

import { PageStatus } from '../../../layout/content/page-status';
import { AdminComponentBase } from '../../../layout/content/admin-component-base';
import { PageSimpleContentComponent } from '../../../layout/content/page-simple-content.component';
import { MatButton } from '@angular/material/button';
import { SaveButtonComponent } from 'app/shared/components/save-button.component';
import { ErrorComponent } from 'app/shared/components/error.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormsModule } from '@angular/forms';
import { DesignerCustomizationsService } from './designer-customizations.service';
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: 'admin-store-designer-custom-css',
    templateUrl: './store-designer-custom-css.component.html',
    styleUrls: ['./store-designer-custom-css.component.scss'],
    imports: [PageSimpleContentComponent, MatButton, SaveButtonComponent, ErrorComponent, CodemirrorModule, FormsModule, MatIcon]
})
export class StoreDesignerCustomCssComponent extends AdminComponentBase implements OnInit, OnDestroy {
    private readonly route = inject(ActivatedRoute);
    private readonly designerCustomizationsService = inject(DesignerCustomizationsService);

    css: string;
    storeId: string;

    ngOnInit(): void {
        this.registerSubscription(this.route.params.subscribe(params => {
            this.storeId = params.storeId;
            this.subscribeWithGenericLoadingErrorHandling(this.designerCustomizationsService.getStoreFiles(this.storeId), storeFiles => {
                if (!storeFiles.css?.url) {
                    this.css = '';
                    this.pageStatus = PageStatus.loaded;
                } else {
                    this.designerCustomizationsService.getCustomizationFile(storeFiles.css?.url).subscribe({
                        next: (css) => {
                            this.css = css;
                            this.pageStatus = PageStatus.loaded;
                        },
                        error: (err) => {
                            this.pageError = err;
                            this.pageStatus = PageStatus.loadError;
                        }
                    });
                }
            });
        }));
    }

    ngOnDestroy() {
        this.unsubscribeSubscriptions();
    }

    save(): void {
        this.subscribeWithGenericSavinErrorHandling(this.designerCustomizationsService.updateCssFile({
            storeId: this.storeId,
            content: this.css
        }));
    }

    async onFilesChanged(files: FileList) {
        if (!files.length) {
            return;
        }
        this.css = await files[0].text();
    }
}
