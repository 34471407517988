import {
    AddStoreStockPhotoSourceInput,
    GraphDotnetService, graphql,
    MediaclipMutationAddStoreStockPhotoSourceArgs,
    MediaclipMutationRemoveStoreStockPhotoSourceArgs,
    RemoveStoreStockPhotoSourceInput,
    StockPhotoSourceConfig
} from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StockPhotoSourcesService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    list(): Observable<StockPhotoSourceConfig[]> {
        return this.graphDotnetService.query(graphql`
                query getStockPhotoSourceConfigurations {
                    stockPhotoSourceConfigurations {
                        id
                        label
                        visible
                        disabled
                        photosEndpoint {
                          url
                        }
                    }
                }
            `
        ).pipe(map(e => e.stockPhotoSourceConfigurations));
    }

    load(stockPhotoSourceId: any): Observable<StockPhotoSourceConfig> {
        return this.graphDotnetService.query(graphql`
            query getStockPhotoSourceConfiguration($stockPhotoSourceId: String!) {
                stockPhotoSourceConfiguration(id: $stockPhotoSourceId) {
                    id
                    label
                    visible
                    disabled
                    thumbnailUrl
                    photosEndpoint {
                      url
                    }
                    albumsEndpoint {
                      url
                    }
                }
            }`,
            { stockPhotoSourceId }
        ).pipe(map(e => e.stockPhotoSourceConfiguration));
    }

    removeFromStore(input: RemoveStoreStockPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationRemoveStoreStockPhotoSourceArgs>(graphql`
                mutation removeStoreStockPhotoSource($input: RemoveStoreStockPhotoSourceInput!) {
                    removeStoreStockPhotoSource(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.removeStoreStockPhotoSource));
    }

    addToStore(input: AddStoreStockPhotoSourceInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationAddStoreStockPhotoSourceArgs>(graphql`
                mutation addStoreStockPhotoSource($input: AddStoreStockPhotoSourceInput!) {
                    addStoreStockPhotoSource(input: $input)
                }
            `, { input }
        ).pipe(map(x => x.addStoreStockPhotoSource));
    }
}
