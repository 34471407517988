import { DesignerModuleName, graphql, Library, Maybe, Product } from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class LibraryService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getProducts(
        storeId: string,
        module: DesignerModuleName | null = null,
        culture: string = 'en-CA'
    ): Observable<Product[]> {
        return this.graphDotnetService.query(graphql`
                query getProducts($storeId: String!, $module: DesignerModuleName, $culture: String) {
                    store(id: $storeId) {
                        products(module: $module, culture: $culture) {
                            id
                            module
                            plu
                            label
                            thumbnail
                        }
                    }
                }
            `,
            {
                storeId,
                module,
                culture
            }
        ).pipe(map(x => x.store.products));
    }

    getProduct(storeId: string, productId: string, culture: string = 'en-CA'): Observable<Maybe<Product> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getProductThemes($storeId: String!, $productId: String!, $culture: String) {
                    store(id: $storeId) {
                        product(id: $productId, culture: $culture) {
                            id
                            module
                            plu
                            label
                            thumbnail
                            themes {
                                id
                                label
                                thumbnail
                            }
                            options {
                                name
                                label
                                default
                                values {
                                    name
                                    label
                                }
                            }
                        }
                    }
                }
            `,
            {
                storeId,
                productId,
                culture
            }
        ).pipe(map(x => x.store.product));
    }
}
