import { Injectable, inject } from '@angular/core';
import {
    DeleteStoreGeneratedTextInput,
    GeneratedTextConfiguration, graphql,
    Maybe,
    MediaclipMutationDeleteStoreGeneratedTextArgs,
    MediaclipMutationUpdateStoreGeneratedTextArgs,
    UpdateStoreGeneratedTextInput
} from 'app/shared/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class StoreGeneratedTextService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getConfig(storeId: string): Observable<Maybe<GeneratedTextConfiguration> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getStoreGeneratedText($storeId: String!) {
                    store(id: $storeId) {
                        generatedText {
                            accessToken
                            organization
                        }
                    }
                }
            `,
            {
                storeId
            }
        ).pipe(map(q => q.store.generatedText));
    }

    deleteConfiguration(input: DeleteStoreGeneratedTextInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteStoreGeneratedTextArgs>(
            graphql`
                mutation deleteStoreGeneratedText($input: DeleteStoreGeneratedTextInput!) {
                    deleteStoreGeneratedText(input: $input)
                }
            `,
            { input }
        );
    }

    updateConfiguration(input: UpdateStoreGeneratedTextInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreGeneratedTextArgs>(
            graphql`
                mutation UpdateStoreGeneratedText($input: UpdateStoreGeneratedTextInput!) {
                    updateStoreGeneratedText(input: $input)
                }
            `,
            { input }
        );
    }
}
