import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { graphql, MediaclipMutationReEncodePhotoArgs } from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({
    providedIn: 'root'
})
export class PhotosService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    reEncodePhoto(urn: string): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationReEncodePhotoArgs>(graphql`
                mutation reEncodePhoto($urn: String!) {
                    reEncodePhoto(urn: $urn)
                }
            `, { urn }
        ).pipe(map(x => x.reEncodePhoto));
    }
}
