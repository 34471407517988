import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { graphql, Library } from 'app/shared/graph';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class LibraryPermissionService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getAuthorizedUsers(owner: string): Observable<Library> {
        return this.graphDotnetService.query(graphql`
            query loadLibrary($owner: String!) {
                library(owner: $owner) {
                    owner
                    authorizedUsers {
                        user {
                            email
                            picture
                        }
                        roles
                    }
                }
            }`, { owner })
            .pipe(map(q => q.library));
    }

}
