import { Fulfiller, FulfillerForm, graphql, Maybe } from 'app/shared/graph';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphDotnetService } from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class FulfillerService {
    private readonly graphDotnetService = inject(GraphDotnetService);

    list(): Observable<Fulfiller[]> {
        return this.graphDotnetService.query(graphql`
                query getFulfillers {
                    fulfillers {
                        id
                        libraryPackage
                        url
                        client
                    }
                }
            `
        ).pipe(map(e => e.fulfillers));
    }

    loadFormData(fulfillerId: string): Observable<Maybe<FulfillerForm> | undefined> {
        return this.graphDotnetService.query(graphql`
                query getFulfiller($fulfillerId: String!) {
                    fulfiller(id: $fulfillerId) {
                        form {
                            documentationLink
                            fields {
                                key
                                label
                                type
                                required
                                description
                                sampleValue
                            }
                        }
                    }
                }
            `,
            { fulfillerId }
        ).pipe(map(e => {
            if (e.fulfiller == null)
                throw new Error('Fulfiller ' + fulfillerId + ' not found');
            return e.fulfiller?.form;
        }));
    }
}
