import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphDotnetService, graphql, VolumeTimelineReportRow } from 'app/shared/graph';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReportsService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    getVolumeTimelineReport(storeId: string): Observable<VolumeTimelineReportRow[]> {
        return this.graphDotnetService.query(graphql`
                query getVolumeTimelineReport($storeId: String!) {
                    store(id: $storeId) {
                        volumeTimelineReport {
                            timestampUtc
                            projectCount
                            orderCount
                        }
                    }
                }
            `,
            { storeId }
        ).pipe(map(x => x.store.volumeTimelineReport));
    }
}
