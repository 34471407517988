import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

export interface ConsolidateUserDialogData {
    anonymousStoreUSerId: string;
}

export interface ConsolidateUserDialogResult {
    targetStoreUserId: string;
}

@Component({
    templateUrl: './consolidate-user-dialog.component.html',
    styleUrls: ['./consolidate-user-dialog.component.scss'],
    imports: [MatDialogTitle, MatDialogContent, MatIcon, FormsModule, MatFormField, MatLabel, MatInput, MatHint, MatDialogActions, MatButton, MatDialogClose]
})
export class ConsolidateUserDialogComponent {
    dialogRef = inject<MatDialogRef<ConsolidateUserDialogComponent, ConsolidateUserDialogResult>>(MatDialogRef);
    data = inject<ConsolidateUserDialogData>(MAT_DIALOG_DATA);

    public targetStoreUserId: string;

    valid() {
        this.dialogRef.close({ targetStoreUserId: this.targetStoreUserId });
    }
}
