import { Injectable, inject } from '@angular/core';
import { GraphDotnetService, graphql } from '../graph/graph-dotnet-service';
import { CreateAppSecret, CreateAppSecretInput, DeleteAppSecretInput, MediaclipMutationCreateAppSecretArgs, MediaclipMutationDeleteAppSecretArgs } from '../graph/schema-dotnet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppSecretService {
    private readonly graphDotnetService = inject(GraphDotnetService);


    createAppSecret(input: CreateAppSecretInput): Observable<CreateAppSecret> {
        return this.graphDotnetService.mutate<MediaclipMutationCreateAppSecretArgs>(graphql`
            mutation createAppSecret($input: CreateAppSecretInput!) {
                createAppSecret(input: $input) {
                id
                label
                secret
              }
            }`, { input })
            .pipe(map(m => m.createAppSecret));
    }

    revokeSecret(input: DeleteAppSecretInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationDeleteAppSecretArgs>(graphql`
            mutation deleteAppSecret($input: DeleteAppSecretInput!) {
              deleteAppSecret(input: $input)
            }`, { input }).pipe(map(m => m.deleteAppSecret!)
        );

    }
}
