import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MediaclipError } from '../../../shared/components/mediaclip-error';
import {
    GraphDotnetService, graphql,
    MediaclipMutationUpdateStoreDesignerCssArgs,
    MediaclipMutationUpdateStoreDesignerJavascriptArgs,
    MediaclipMutationUpdateStoreDesignerLocaleArgs,
    MediaclipMutationUpdateStoreDesignerLogoArgs,
    StoreDesignerCustomization,
    UpdateLocaleStoreFilesInput,
    UpdateStoreBinaryFilesInput,
    UpdateStoreFilesInput
} from 'app/shared/graph';

@Injectable({ providedIn: 'root' })
export class DesignerCustomizationsService {
    protected readonly graphDotnetService = inject(GraphDotnetService);
    private readonly httpClient = inject(HttpClient);


    getCustomizationFile(url: string) {
        return this.httpClient
            .get(url, {
                responseType: 'text'
            })
            .pipe(
                catchError((err) => {
                    if (err instanceof HttpErrorResponse && err.status === 404) {
                        return of('');
                    }
                    throw new MediaclipError(`Unable to load customization file at ${url}`, { innerError: err });
                })
            );
    }

    updateJavascriptFile(input: UpdateStoreFilesInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDesignerJavascriptArgs>(graphql`
                mutation updateStoreDesignerJavascript($input: UpdateStoreFilesInput!) {
                    updateStoreDesignerJavascript(input: $input)
                }
            `, { input }
        ).pipe(map((result) => result.updateStoreDesignerJavascript!));
    }

    updateCssFile(input: UpdateStoreFilesInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDesignerCssArgs>(graphql`
                mutation updateStoreDesignerCss($input: UpdateStoreFilesInput!) {
                    updateStoreDesignerCss(input: $input)
                }
            `, { input }
        ).pipe(map((result) => result.updateStoreDesignerCss!));
    }

    updateLocale(input: UpdateLocaleStoreFilesInput): Observable<void> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDesignerLocaleArgs>(graphql`
                mutation updateStoreDesignerLocale($input: UpdateLocaleStoreFilesInput!) {
                    updateStoreDesignerLocale(input: $input)
                }
            `, { input }
        ).pipe(map((result) => result.updateStoreDesignerCss!));
    }

    updateLogo(input: UpdateStoreBinaryFilesInput): Observable<any> {
        return this.graphDotnetService.mutate<MediaclipMutationUpdateStoreDesignerLogoArgs>(graphql`
                mutation updateStoreDesignerLogo($input: UpdateStoreBinaryFilesInput!) {
                    updateStoreDesignerLogo(input: $input)
                }
            `, { input }
        ).pipe(map((result) => result.updateStoreDesignerLogo!));
    }

    getStoreFiles(storeId: string): Observable<StoreDesignerCustomization> {
        return this.graphDotnetService.query(graphql`
                query getStoreFiles($storeId: String!) {
                    store(id: $storeId) {
                        designerCustomization {
                            css {
                                filename
                                url
                            }
                            logo {
                                filename
                                url
                            }
                            javascript {
                                filename
                                url
                            }
                            locales {
                                culture
                                filename
                                url
                            }
                        }
                    }
                }
            `,
            { storeId }
        ).pipe(map(r => r.store.designerCustomization));
    }
}
