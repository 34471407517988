import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatButton } from '@angular/material/button';

export interface UpdateSubscriptionInformationDialogData {
    label?: string;
    contact?: string;
    notes?: string;
}

export interface UpdateSubscriptionInformationDialogResult {
    label: string;
    contact: string;
    notes?: string;
}

@Component({
    templateUrl: 'update-subscription-info-dialog.component.html',
    styleUrls: ['update-subscription-info-dialog.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatFormField, MatLabel, MatInput, CdkTextareaAutosize, MatDialogActions, MatButton, MatDialogClose]
})
export class UpdateSubscriptionInformationDialogComponent {
    dialogRef = inject<MatDialogRef<UpdateSubscriptionInformationDialogComponent, UpdateSubscriptionInformationDialogResult>>(MatDialogRef);

    result: UpdateSubscriptionInformationDialogResult;

    constructor() {
        const data = inject<UpdateSubscriptionInformationDialogData>(MAT_DIALOG_DATA);

        this.result = {
            contact: data.contact || '',
            label: data.label || '',
            notes: data.notes
        };
    }

    editSubscription() {
        this.dialogRef.close(this.result);
    }
}
